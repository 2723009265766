const Footer = () => {
    return (
        <div className="white-box">
            <div className="container" style={{ maxWidth: '1600px' }}>
                <div className="content-margin text-center footer p-4" style={{marginTop: '0', marginBottom: '0'}}>
                    <div className="row g-3">
                        <div className="col-md-6">
                            <div className="footer-left">
                                <a href="#">Legals</a>&nbsp;|&nbsp;
                                <a href="#">Terms & Conditions</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-right">
                                <div style={{ textTransform: 'uppercase' }}>
                                    Designed & Developed by <a href="https://rennovationsoftware.com">Rennovation Software</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;