import AppBar from "./AppBar";
import Footer from "./Footer";
import heroSecond from "../assets/hero-bg.jpg";
import upiscan from "../assets/upi_1724667820363.png";

const BlackDrongo = () => {
    return (
        <>
            <AppBar />
            <div className="white-box" style={{ backgroundImage: `url(${heroSecond})`, backgroundPosition: "center", backgroundSize: "cover", borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}>
                <div className="container" style={{ maxWidth: '1600px' }}>
                    <div className="content-margin py-4" style={{ marginTop: '0', marginBottom: '0' }}>
                        <h4 style={{ textTransform: "uppercase" }}>Loveshore</h4>
                        <h3 style={{ textTransform: "uppercase" }}>Black Drongo</h3>
                        <p className="title">International Short Film Festival</p>
                        <p className="title">First Half 2024</p>
                        <span>in association with</span>
                        <h4>DOCUSHOT</h4>
                    </div>
                </div>
            </div>
            <div className="white-box">
                <div className="container" style={{ maxWidth: '1600px' }}>
                    <div className="content-margin" style={{ marginTop: '0', marginBottom: '0' }}>
                        <div className="pt-5">
                            <h4>CATEGORIES</h4>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="text-left">Best Short Film</td>
                                        <td style={{ textAlign: "right" }}>₹ 1,00,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Audience Poll Short Film<br />
                                            <span className="fs-small text-muted">Audience Poll - Above 50K views</span></td>
                                        <td style={{ textAlign: "right" }}>₹ 1,00,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Students film (Based on Audience poll)<br />
                                            <span className="fs-small text-muted">Audience Poll - Above 50K views</span></td>
                                        <td style={{ textAlign: "right" }}>₹ 75,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Musical Film / Album (Based on Audience poll)<br />
                                            <span className="fs-small text-muted">Audience Poll - Above 50K views</span></td>
                                        <td style={{ textAlign: "right" }}>₹ 50,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Mobile Film (Based on Audience poll)<br />
                                            <span className="fs-small text-muted">Audience Poll - Above 30K views</span></td>
                                        <td style={{ textAlign: "right" }}>₹ 25,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td claclassNamess="text-left">Best Reels Film (Based on Audience poll)<br />
                                            <span className="fs-small text-muted">Audience Poll - Above 30K views</span></td>
                                        <td style={{ textAlign: "right" }}>₹ 25,000 & Memento</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="pt-4 pb-4">
                            <h4>OTHER CATEGORIES</h4>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="text-left">Best Short Film Director</td>
                                        <td style={{ textAlign: "right" }}>₹ 35,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Actor</td>
                                        <td style={{ textAlign: "right" }}>₹ 25,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Actress</td>
                                        <td style={{ textAlign: "right" }}>₹ 25,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Cameraperson (Short Film)</td>
                                        <td style={{ textAlign: "right" }}>₹ 15,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Editor (Short Film)</td>
                                        <td style={{ textAlign: "right" }}>₹ 15,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Script Writer</td>
                                        <td style={{ textAlign: "right" }}>₹ 15,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Music Director (Short Film)</td>
                                        <td style={{ textAlign: "right" }}>₹ 10,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Make-Up Artist</td>
                                        <td style={{ textAlign: "right" }}>₹ 5,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Supporting Actress</td>
                                        <td style={{ textAlign: "right" }}>₹ 5,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Supporting Actor</td>
                                        <td style={{ textAlign: "right" }}>₹ 5,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Sound Effects</td>
                                        <td style={{ textAlign: "right" }}>₹ 5,000 & Memento</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Best Visual Effects</td>
                                        <td style={{ textAlign: "right" }}>₹ 5,000 & Memento</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="lc-card py-4 mb-4">
                            <p>മത്സരത്തിനായി അയയ്ക്കുന്ന ചിത്രങ്ങൾ ലൗഷോർ കമ്മ്യൂണിക്കേഷൻസിൻറെ ചാനലുകളിൽ സംപ്രേഷണം ചെയ്യും.
                                ദിവസം 4 തവണയായിരിക്കും ഓരോ ചിത്രത്തിൻറെയും പ്രദർശനം. ഇങ്ങനെ 10 ദിവസം (ഒക്ടോബര്‍ 15, 2024 മുതല്‍ ജനുവരി 15, 2025
                                വരെയുള്ള മാസങ്ങളിൽ നേരത്തെ പ്രഖ്യാപിക്കുന്ന ദിവസങ്ങളിൽ) ഒരു ചിത്രത്തിന്റെ സംപ്രേഷണം നടക്കും.
                                ഇതേ രീതിയിൽ എല്ലാ സിനിമകളും പ്രദർശിപ്പിച്ച കഴിയുമ്പോൾ ജൂറി തെരെഞ്ഞടുക്കുന്ന ചിത്രങ്ങളും ഏറ്റവും
                                കൂടുതൽ വ്യൂ (നിബന്ധനകളിൽ പറയുന്ന പ്രകാരം - 50k and/or 30k) ലഭിച്ച ചിത്രങ്ങളും
                                സമ്മാനാർഹമായിരിക്കും.</p>
                            <p>മത്സരത്തിൽ പങ്കെടുക്കുന്നവരും ഒപ്പം ചിത്രം കാണുന്നതിനായും ലൗഷോർ മീഡിയ ആപ്ലിക്കേഷൻ പ്ലേസ്റ്റോറിൽ
                                നിന്നും ഡൗൺലോഡ് ചെയ്ത് ആൻഡ്രോയിഡ് ടിവിയിലോ മൊബൈൽ ഫോണിലോ കാണാം. ലൗഷോർ ടിവി ലൈവ് സ്ട്രീമിംഗ്
                                ആയിരിക്കും. പ്രദർശനത്തിൻ്റെ ഷെഡ്യൂൾ സോഷ്യൽ മീഡിയ വഴിയും ലൗഷോർ ടിവി വഴിയും പൊതുജനങ്ങൾക്ക് കാണാം.
                                പ്രദർശനത്തിനായി സിനിമകൾ അയച്ചിട്ടുള്ളവരെ വാട്സ്ആപ്പ് വഴി പ്രദർശനത്തിൻ്റെ ഷെഡ്യൂൾ
                                നൽകുന്നതായിരിക്കും.</p>
                            <p>യൂട്യൂബിൽ പ്രദർശിപ്പിച്ചിട്ടുള്ള ചിത്രങ്ങൾ മത്സരയോഗ്യമായിരിക്കില്ല. അങ്ങനത്തെ ചിത്രങ്ങൾ അയയ്ക്കാൻ
                                ആഗ്രഹിക്കുന്നവർ അത് യൂട്യൂബിൽ നിന്നും പിൻവലിച്ചിട്ടുള്ളതായി കാണിക്കുന്ന രേഖകൾ സബ്മിഷനോടൊപ്പം
                                ഹാജരാക്കണം. ഫെസ്റ്റിവലിൽ പങ്കെടുക്കുന്ന കാലയളവിൽ അതേ ചിത്രങ്ങൾ സമാനമായ മറ്റ് ചാനലുകളിൽ
                                പ്രദർശിപ്പിക്കാൻ പാടില്ല.</p>
                            <br />
                            <p>Submit the films through <b>WeTransfer</b> or send link of <b>Google Drive</b> or through <b>Dropbox</b> and share to <strong> <a
                                href="mailto:loveshoremedia88@gmail.com">loveshoremedia88@gmail.com</a></strong>
                            </p>
                        </div>
                        <div className="lc-card py-4 mb-4">
                            <h4>Click below to submit your entry forms</h4>
                            <div class="col-md-6 col-md-offset-3">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfdKzEF0eeGv6TKk4qSusF7SW4Tkqfd99aoXNv-wmyzJJH9JQ/viewform?usp=sf_link"
                                    class="btn btn-warning">Entry Form</a>
                            </div>
                        </div>
                        <div className="lc-card py-4 mb-4">
                            <h4>Rules & Regulations</h4>
                            <ol>
                                <li>Entry fee for films submitting on or before <b>10th October 2024</b> is <b>Rs.1000</b>. Entry fee
                                    for <b>Reels Film</b> is <b>Rs.500</b>.</li>
                                <li>The other Dates for Submissions are <b>15th November 2024</b> with an entry fee of <b>Rs.1200</b> (<b>Reels - Rs.750</b>) 
                                    and till <b>10th December 2024</b> films will be accepted with an entry fee of&nbsp;
                                    <b>Rs.1500 (Reels Rs.1000)</b> per film. Entries are accepted till <b>31st December 2024</b> with an
                                    entry fee of <b>Rs.2000 (Reels - Rs.1300)</b> (extended). Entry for <b>Students is FREE</b>.</li>
                                <li>Entries for the Loveshore Black Drongo International Short Film Festival are open for
                                    everyone. Participants under the legal age must submit a consent letter from their parent
                                    or guardian.</li>
                                <li>There shall be 5 categories in the festival/award: Short Fiction, Student Film (short
                                    fiction), Mobile phone Film, Musical Film, Reels Film. Films may be entered by any
                                    organization, agency, individual producer or director.</li>
                                <li>A complete schedule of top selected films in each category shall be put up on the
                                    LOVESHORE MEDIA website.</li>
                                <li>Censorship will not be applicable to any films / videos entered in the festival.</li>
                                <li>The Festival Authorities will have the right to screen on LOVESHORE
                                    COMMUNICATIONS (maximum 4 screening per day) in different times in three months
                                    and ten days according to the viewers' demand.</li>
                                <li>For promotional / publicity purposes, excerpts from any film entered in the festival may
                                    be shown on any Television network / Internet or related media during the festival period.
                                </li>
                                <li>The Films can be based on any subject or concept.</li>
                                <li>The films can be in any language. However, all films other than Malayalam must include
                                    sub-titles in English or Malayalam.</li>
                                <li>Prize and certificate will be awarded to the director (s) or producer(s) of the film. For
                                    any
                                    duties, fees and charges accrued from the transporting of the trophy, will be covered by
                                    the recipient, not the festival organizers.</li>
                                <li>There will be NO PRIZE in the AUDIENCE POLL if any film is not achieving the
                                    targeted views. (30k or 50k)</li>
                                <li>All content used in the short film must be either owned or licensed by the applicant. If
                                    any copyrighted material is used in the film, the participant must have the permission and
                                    submit a copy to the Festival Organizers.</li>
                                <li>Short films must be uploaded to our g-mail id (loveshoremedia88@gmail.com) through
                                    WeTransfer or other online services like Google Drive or Dropbox and must be madeavailable
                                    for download to the festival organizers. YouTube & Vimeo links are not
                                    accepted.</li>
                                <li>Only Short Films produced & completed in HD format or higher will be accepted.
                                    Standard format (4:3) films are not accepted</li>
                                <li>Multiple entries from the same participant are accepted, but each film must be
                                    accompanied by a separate form.</li>
                                <li>The Loveshore Black Drongo International Short Film Festival 2024 will be undertaken
                                    as an online-only (LOVESHORE) event, with the support of ADVERTISEMENTS. The
                                    screening of all officially selected films will be done LOVESHORE on telecasting, cloud
                                    television purposefully built for programme and short film telecasting with the support of
                                    ADVERTISEMENTS. Participating short films will NOT be shared outside the screening
                                    channel.</li>
                                <li>Films playing in YouTube are not eligible for this festival. However, you can send those
                                    films by removing the same from the YouTube and have to submit relevant documents
                                    showing that the film is not in YouTube in the period of this festival.</li>
                                <li>Loveshore Communications reserves the rights to copy or use the material submitted by
                                    the participants in any form, online or offline, for the festival’s promotional and archival
                                    purposes. Note that your entire short film and documentary will never be shared with
                                    anyone or uploaded online without your permission. We understand that filmmakers want
                                    to keep the films private, and we will honor that.</li>
                                <li>The short films that are selected for final screening will not be allowed to withdraw under
                                    any circumstances until the festival is over.</li>
                                <li>Jury’s decision will be final and binding.</li>
                                <li>Festival Organizers and Loveshore Communications reserve the right to change / modify
                                    / alter any rule or its part or event of this competition anytime.</li>
                                <li>Minimum duration of SHORT FILM is 8 minutes and Maximum is 60minutes. However,
                                    duration of  REELS film is minimum of 1 minute and maximum is 8 minutes.</li>
                                <li><b>Participation in the Festival implies acceptance of these Regulations.</b></li>
                            </ol>
                        </div>
                        <div className="lc-card py-4">
                            <h4>Payment of Entry Fee</h4>
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <img alt="" src={upiscan} style={{ width: '100%' }} />
                                </div>
                                <div className="col-md-6">
                                    <p>
                                        <strong>Bank Transfer Details</strong><br />
                                        Loveshore Media Communications<br />
                                        Account No. 42587770853<br />
                                        SBI - Vadanappally Branch<br />
                                        IFSC - SBIN0008683
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BlackDrongo;