import logo from '../assets/loveshoremedia-logo.png';

const AppBar = () => {
    return (
        <div className="app-header">
            <div className='app-logo'>
                <a></a>
                <img src={logo} style={{ width: '40px', marginRight: 10 }} />
                <span className="app-title">LOVESHORE Media Communications</span>
            </div>
            <nav className='app-menu'>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/#aboutus">About Us</a></li>
                    <li><a href="/#career">Career</a></li>
                    <li><a className='selected' href="/#getintouch">Get in Touch</a></li>
                </ul>
            </nav>
        </div>
    );
}

export default AppBar;