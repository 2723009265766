import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import BlackDrongo from './pages/BlackDrongo';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />}></Route>
        <Route path='/' element={<Home />}></Route>
        <Route path='/loveshoreblackdrongo' element={<BlackDrongo />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
